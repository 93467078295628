/**
 * Manages listeners that they can be easily destroyed later.
 *
 * @copyright Aaron Waldon <aaron@causingeffect.com> 2019
 */
export default class ListenerManager {
	/**
	 * The tracked listeners.
	 * @type {Array}
	 * @private
	 */
	_tracked = []

	/**
	 * Registers and tracks an event listener.
	 *
	 * @param target
	 * @param type
	 * @param listener
	 * @param options
	 */
	register(target, type, listener, options = {}) {
		if (target) {
			if (target.addEventListener) {
				target.addEventListener(type, listener, options)
				this.track(target, type, listener, options)
			} else if (target.attachEvent) {
				target.attachEvent('on' + type, listener)
				this.track(target, type, listener, options)
			}
		}
	}

	/**
	 * Tracks an event listener
	 * @param target
	 * @param type
	 * @param listener
	 * @param options
	 */
	track(target, type, listener, options = {}) {
		if (target) {
			this._tracked.push({ target, type, listener, options })
		}
	}

	/**
	 * Registers and tracks an event listener.
	 *
	 * @param target
	 * @param type
	 * @param listener
	 * @param options
	 */
	remove(target, type, listener, options = {}) {
		if (target) {
			if (target.removeEventListener) {
				target.removeEventListener(type, listener, options)
			} else if (target.detachEvent) {
				target.detachEvent('on' + type, listener)
			}

			this._tracked.forEach((tracked, trackedIndex) => {
				if (tracked === { target, type, listener, options }) {
					this._tracked.splice(trackedIndex, i)
				}
			})
		}
	}

	/**
	 * Remove all items.
	 */
	removeAll() {
		this._tracked.forEach(item => {
			if (item) {
				if (item.target.removeEventListener) {
					item.target.removeEventListener(item.type, item.listener, item.options)
				} else if (item.target.detachEvent) {
					item.target.detachEvent('on' + item.type, item.listener)
				}
			}
		})
		this._tracked = []
	}
}
