import debounce from 'debounce'

/**
 * Make mobile vh work like desktop vh.
 * Idea from CSS Tricks.
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */

let styleTag

/**
 * Create the tag.
 */
const createTag = function () {
	styleTag = document.createElement('style')
	styleTag.type = 'text/css'
	document.getElementsByTagName('head')[0].appendChild(styleTag)
}

/**
 * Update the vh value.
 */
const updateVh = function () {
	let vh = window.innerHeight * 0.01
	styleTag.innerHTML = 'body { --vh: ' + vh + 'px }'
}

export default function () {
	//create the tag
	createTag()

	//update the vh
	updateVh()

	//update the vh on resizes
	window.addEventListener('resize', debounce(updateVh, 100, false))
}
