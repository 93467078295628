//https://css-tricks.com/ajaxing-svg-sprite/
//https://stackoverflow.com/a/15511881/1136822

let ajax = new XMLHttpRequest()
let iconSpriteUrl = '/dist/svg/icons'
if (window.iconHash) {
	iconSpriteUrl += '.' + window.iconHash
}
iconSpriteUrl += '.svg'

ajax.open('GET', iconSpriteUrl, true)
ajax.send()
ajax.onload = function (e) {
	let div = document.createElement('div')
	div.classList.add('svgsprite')
	div.classList.add('hidden')
	div.innerHTML = ajax.responseText.replace(/\<\?xml.+\?\>|\<\!DOCTYPE.+]\>/g, '')
	document.body.insertBefore(div, document.body.childNodes[0])
}
