import debounce from 'debounce'

/*
 * Responsive Videos
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

//all of the watched videos
let allVideos = []

//the parent class
let parentClass = ''

//the aspect ratio attribute
let ratioAttribute = 'data-aspect-ratio'

/**
 * Wraps video elements in a div with the parent class and adds them
 * to the array of allVideos elements to keep track of.
 *
 * @param {nodelist} els
 */
const addVideos = els => {
	if (els && els.length) {
		//initialize
		els.forEach(el => {
			if (!el.hasAttribute(ratioAttribute)) {
				//set aspect ratio
				el.setAttribute(ratioAttribute, el.offsetHeight / el.offsetWidth)

				//remove any previously set width or height attributes
				el.removeAttribute('height')
				el.removeAttribute('width')

				//wrap the element in a div with the parent class, if it isn't already wrapped
				if (!el.parentNode || !el.parentNode.classList.contains(parentClass)) {
					let wrapper = document.createElement('div')
					wrapper.classList.add(parentClass)
					el.parentNode.insertBefore(wrapper, el)
					wrapper.appendChild(el)
				}

				//combine el into allVideos
				allVideos.push(el)
			}
		})
	}
}

/**
 * Resizes the video elements based on their respective parent's width and their display ratio.
 */
const resizeVideos = () => {
	allVideos.forEach(el => {
		let newWidth = el.parentNode.offsetWidth
		let newHeight = newWidth * el.getAttribute(ratioAttribute)
		newWidth = Math.ceil(newWidth)
		newHeight = Math.floor(newHeight)
		el.style.width = newWidth + 'px'
		el.style.height = newHeight + 'px'
	})
}

export default {
	init(
		selector = "iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com'], iframe[src*='//www.youtube-nocookie.com'], object, embed",
		wrapperClass = 'video-player',
	) {
		this.destroy()

		let els = document.querySelectorAll(selector)
		parentClass = wrapperClass
		addVideos(els)

		//watch
		;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
			window.addEventListener(eventName, debounce(resizeVideos, 10, false))
		})
	},
	destroy() {
		allVideos = []
	},
}
