import ListenerManager from './listenerManager'
import debounce from 'debounce'
let listenerBoss = new ListenerManager()

let nav = document.querySelector('.header-nav.nav')
let navSlider = document.querySelector('[data-nav-slider]')
let topLevelNavLis = document.querySelectorAll('.header-nav.nav > ul > li')
let navSliderTimer
let navSliderPadding = 4

const backToBase = function () {
	let selectedNavLink = document.querySelector('.header-nav.nav > ul > li > a.selected')
	if (selectedNavLink) {
		moveSliderToLink(selectedNavLink)
	} else {
		navSlider.style.width = 0
		navSlider.style.opacity = 0
		navSlider.style.height = 0
	}
}

const moveSliderToLink = function (link) {
	var linkBounds = link.getBoundingClientRect()
	var linkLeftPadding = parseInt(window.getComputedStyle(link, null).getPropertyValue('padding-left'), 10)

	//set the width
	var widthWithoutPadding = Math.round(linkBounds.width - linkLeftPadding + navSliderPadding * 2)
	navSlider.style.width = widthWithoutPadding + 'px'

	//set the left position
	var relativeOffset = Math.round(
		linkBounds.left -
			nav.getBoundingClientRect().left +
			linkLeftPadding -
			navSliderPadding +
			widthWithoutPadding * 0.5,
	)
	navSlider.style.left = relativeOffset + 'px'

	//set the other styles
	navSlider.style.height = '3px'
	navSlider.style.opacity = 1
}

//set up the hover events
if (topLevelNavLis.length) {
	topLevelNavLis.forEach(topLevelNavLi => {
		listenerBoss.register(topLevelNavLi, 'mouseenter', function (e) {
			if (e.target === topLevelNavLi) {
				let anchor = topLevelNavLi.querySelector(':scope > a')
				if (anchor) {
					clearTimeout(navSliderTimer)
					moveSliderToLink(anchor)
				}
			}
		})
		listenerBoss.register(topLevelNavLi, 'mouseleave', function (e) {
			if (e.target === topLevelNavLi) {
				navSliderTimer = setTimeout(backToBase, 330)
			}
		})
	})
}

;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
	window.addEventListener(
		eventName,
		debounce(
			function () {
				backToBase()
			},
			10,
			false,
		),
	)
})

export default {
	backToBase,
}
