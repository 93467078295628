// ---------- Cookie Consent ----------
import 'cookieconsent'

cookieconsent.initialise({
	palette: {
		popup: {
			background: '#dbe8f4',
			text: '#02304a',
		},
		button: {
			background: '#0d6db7',
		},
	},
	theme: 'classic',
	content: {
		message: gdprWording,
		dismiss: 'Close',
		link: gdprLinkText,
		href: gdprLink,
	},
	cookie: {
		name: "gdpr_cookieconsent_status",
		expiryDays: 1
	}
})

// ---------- Variables ----------
let html = document.querySelector('html')
const scrollSpeed = 500
const scrollOffset = 0
const scrollHeader = '[data-scroll-header]'

// ---------- update HTML classes ----------
import './Components/jsClass'
import './Components/ie11Class'

// ---------- polyfills / accessibility ----------
import 'element-qsa-scope'
import 'focus-visible/dist/focus-visible'
import focusWithin from 'focus-within'
import 'svgxuse'
import 'wicg-inert/dist/inert'
import 'keyboardFocus.js/src/keyboard-focus'

// ---------- aos effects ----------
import effects from './Components/effects'
const noEffectsClass = 'effects--none'
const runEffects = function () {
	//run effects, except for CSR pages
	let currentPath = window.location.pathname
	if (currentPath.indexOf('/csr') === 0) {
		effects.disableAos()
		html.classList.add(noEffectsClass)
	} else {
		html.classList.remove(noEffectsClass)
		effects.restoreAos()
		effects.run()
	}
}
runEffects()

// ---------- lazysizes ----------
import lazySizes from 'lazysizes'
//import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
Object.assign(lazySizes.cfg, { loadMode: 1 })

// ---------- smooth scroll ----------
import SmoothScrollLinks from './Components/smoothScroll'
SmoothScrollLinks.init(scrollSpeed, scrollOffset, scrollHeader)

// ---------- floating header ----------
import floating from './Components/floatingElement'
floating.init()

// ---------- global elements and classes ----------
const holderEl = document.querySelector('.holder')
const navEl = document.querySelector('.header-menu')
const navToggleEl = document.querySelector('.header-toggle')
let previouslyActiveEl = false
const navOpenClass = 'menu--open'

// ---------- mobile menu ----------
const headerEl = document.getElementById('header')
import MobileMenu from './Components/mobileMenu'
const nav = new MobileMenu({
	openClass: 'menu--isOpen',
	closeClass: 'menu--isClosed',
	closeSelector: '[data-nav-close]',
	toggleSelector: '[data-nav-toggle]',
	onOpen() {
		//make the nav el not inert
		navEl.inert = false

		//add the nav open class (used for transitions)
		navEl.classList.add(navOpenClass)

		//set the previously active item
		previouslyActiveEl = document.activeElement

		//update the toggles for accessibility
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', true)

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Close menu')
		})

		//update the closers for accessibility
		this.mobileMenuClosers.forEach(mobileMenuCloser => {
			//set the aria expanded attributes
			mobileMenuCloser.setAttribute('aria-expanded', true)

			//set the aria label attribute
			mobileMenuCloser.setAttribute('aria-label', 'Close menu')

			//make sure it's not hidden
			mobileMenuCloser.removeAttribute('hidden')
		})

		//focus the nav logo
		navToggleEl.focus()
	},
	onClose() {
		headerEl.scrollTop = 0
		headerEl.scrollLeft = 0

		//make the nav el not inert
		navEl.inert = true

		//remove the nav open class (used for transitions)
		navEl.classList.remove(navOpenClass)

		//update the toggles for accessibility
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', false)

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Open Menu')
		})

		//update the closers for accessibility
		this.mobileMenuClosers.forEach(mobileMenuCloser => {
			//set the aria expanded attributes
			mobileMenuCloser.setAttribute('aria-expanded', false)

			//optionally hide
			if (mobileMenuCloser.getAttribute('data-nav-close') === 'hide') {
				mobileMenuCloser.setAttribute('hidden', '')
			}
		})

		//attempt to restore focus
		if (previouslyActiveEl) {
			previouslyActiveEl.focus()
		}

		this.handleResponsive()
	},
	onResponsive(screenWidth) {
		if (screenWidth >= 1200) {
			//desktop - close the mobile menu if it's open
			if (this.mobileMenuIsOpen) {
				this.close()
			}
			//make sure the nav is not inert
			if (navEl.inert) {
				navEl.inert = false
			}
		} else {
			//mobile - make sure the nav is inert if closed
			if (!this.mobileMenuIsOpen && !navEl.inert) {
				navEl.inert = true
			}
		}
	},
})
nav.close()

// ---------- svg sprite ----------
import './Components/svgSprite'

// ---------- responsive videos ----------
import responsiveVideos from './Components/responsiveVideos'

// ---------- lightbox ----------
import MediaLightbox from './Components/lightbox'
let lightbox = new MediaLightbox({
	onOpen() {
		if (holderEl) {
			holderEl.inert = true
		}
	},
	onClose() {
		if (holderEl) {
			holderEl.inert = false
		}
	},
})
window.lightbox = lightbox

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation'
scrollbarCompensation()

// ---------- mobile vh fix ----------
import mobileVh from './Components/mobileVh'
mobileVh()

// ---------- top bar ----------
import topbar from 'topbar'
topbar.config({
	barColors: { 0: 'rgba(74, 212, 223, 1)', 1: 'rgba(76, 130, 233, 1)' },
	shadowBlur: 0,
	shadowColor: 'rgba(0, 0, 0, 0)',
})

// ---------- nav ----------
import updateNav from './Components/updateNav'
import navSlider from './Components/navSlider'
import blurOnClick from './Components/blurOnClick'

// ---------- pdf merge ----------
import PDFMerge from './Components/pdfMerge'
import TableTabs from './Components/tableTabs'
import Milestones from './Components/milestones'

// ---------- on page load ----------
let lastPageClass = false
const onPageLoad = function (isInitialLoad = false) {
	const urlParams = window.location.pathname.split('/')
	if (lastPageClass) {
		document.body.classList.remove(lastPageClass)
	}
	lastPageClass = `page-section-${urlParams[1]}`
	document.body.classList.add(lastPageClass)

	const pdfMerges = document.querySelectorAll('.pdf-merge')
	if (pdfMerges.length) {
		pdfMerges.forEach(pdfMerge => {
			new PDFMerge(pdfMerge)
		})
	}

	const tabbedTables = document.querySelectorAll('.table-tabs')
	if (tabbedTables.length) {
		tabbedTables.forEach(tabbedTable => {
			new TableTabs(tabbedTable)
		})
	}

	const milestonesEls = document.querySelectorAll('.milestones')
	if (milestonesEls.length) {
		milestonesEls.forEach(milestonesEl => {
			new Milestones(milestonesEl)
		})
	}

	// ---------- nav ----------
	updateNav()
	navSlider.backToBase()
	blurOnClick.init()

	// ---------- csrf tokens ----------
	window.csrfTokenName = document.querySelector('meta[name="csrf-name"]').getAttribute('content')
	window.csrfTokenValue = document.querySelector('meta[name="csrf-value"]').getAttribute('content')

	// ---------- sliders ----------
	let sliders = document.querySelectorAll('[data-flickity-slider]')
	if (sliders.length) {
		import(/* webpackChunkName: "sliders", webpackPreload: true */ './Components/flickitySliders').then(
			({ default: flickitySliders }) => {
				flickitySliders.init({
					readyCallback: function () {
						//reload aos effects
						runEffects()
					},
				})
			},
		)
	}

	// ---------- accordions ----------
	let accordionEls = document.querySelectorAll('[data-accordion]')
	if (accordionEls.length) {
		import(/* webpackChunkName: "accordions", webpackPreload: true */ './Components/accordions').then(
			({ default: accordions }) => {
				accordions.init({
					readyCallback: function (accordionGroupEl) {
						//if this is a timeline accordion, open the first point after half a second
						if (accordionGroupEl.classList.contains('timeline')) {
							let firstTimelineToggle = accordionGroupEl.querySelector('[data-accordion-toggle]')
							if (firstTimelineToggle) {
								setTimeout(() => firstTimelineToggle.click(), 500)
							}
						}

						requestAnimationFrame(function () {
							//reload aos effects
							runEffects()
						})
					},
				})
			},
		)
	}

	// ---------- videos ----------
	let videos = document.querySelectorAll('[data-videojs]')
	if (videos.length) {
		//video js options
		let videoJsOptions = {
			autoplay: false,
			controlBar: {
				children: ['playToggle', 'progressControl', 'volumePanel', 'fullscreenToggle'],
				volumePanel: {
					inline: false,
					volumeControl: {
						vertical: true,
					},
				},
			},
			fluid: true,
			muted: false,
			preload: 'auto',
		}

		//lazyload all of the videojs styles and js
		Promise.all([
			import(/* webpackChunkName: "videojs", webpackPreload: true */ 'video.js'),
			import(/* webpackChunkName: "videojs", webpackPreload: true */ 'video.js/dist/video-js.min.css'),
			import(/* webpackChunkName: "videojs", webpackPreload: true */ '../scss/vendor-overrides/_videojs.scss'),
		]).then(([{ default: videojs }]) => {
			videos.forEach(video => {
				let ratio = video.getAttribute('data-videojs-ratio') || '16:9'
				try {
					videoJsOptions.aspectRatio = ratio
					videojs(video, videoJsOptions)
				} catch (e) {
					console.log(e)
				}
			})
		})
	}

	// ---------- responsive videos ----------
	if (!isInitialLoad) {
		responsiveVideos.destroy()
	}
	responsiveVideos.init()

	// ---------- aos ----------
	if (!isInitialLoad) {
		runEffects()
	}

	// ---------- lightbox ----------
	lightbox.init()
	if (!isInitialLoad) {
		//attempt to focus the page's h1
		let h1El = document.querySelector('h1')
		if (h1El) {
			h1El.tabIndex = -1
			h1El.focus()
		}
	}
}
onPageLoad(true)

document.addEventListener('pjax:success', () => {
	onPageLoad(false)
})
document.addEventListener('pjax:send', () => {
	// ---------- nav ----------
	nav.close()

	// ---------- lightbox ----------
	lightbox.close()

	// ---------- topbar ----------
	topbar.show()
})

document.addEventListener('pjax:complete', topbar.hide)

document.addEventListener('pjax:error', e => {
	console.log('pjax error', e)
	if (e.hasOwnProperty('request') && (e.request.status === 301 || e.request.status === 302)) {
		window.location.replace(e.request.responseURL)
	}
})

// ---------- pjax ----------
// import Pjax from 'pjax'
// new Pjax({
// 	elements:
// 		'a[href]:not(.no-pjax):not([href*=".gif"]):not([href*=".jpg"]):not([href*=".png"]):not([href*=".pdf"]):not([href*=".json"]), form[method="get"]',
// 	selectors: [
// 		'head > title',
// 		'meta[name="description"]',
// 		'meta[name="csrf-name"]',
// 		'meta[name="csrf-value"]',
// 		'.main',
// 	],
// 	cacheBust: false,
// })

// ---------- focus within ----------
focusWithin(document)
