import { IntClamp } from '../Utils/intClamp'

class Milestones {
	constructor(element) {
		// Initialize
		this.root = element
		// Check for Slider
		this.slideWrapper = this.root.querySelector('[data-slider]')
		if (!this.slideWrapper) return
		// Check for Slides
		this.slides = this.slideWrapper.querySelectorAll('[data-slide]')
		if (!this.slides.length) return
		// Set Active
		this.activeMilestone = new IntClamp(this.slides.length - 1, 0, { loop: true })

		this.dir = false

		this.prev = this.root.querySelectorAll('[data-prev]')
		if (this.prev.length) {
			this.prev.forEach(prev => {
				prev.addEventListener('click', e => {
					this.dir = 'prev'
					this.activeMilestone.prev()
				})
			})
		}
		this.next = this.root.querySelectorAll('[data-next]')
		if (this.next.length) {
			this.next.forEach(next => {
				next.addEventListener('click', e => {
					this.dir = 'next'
					this.activeMilestone.next()
				})
			})
		}

		this.ghostSlides = []
		if (this.slides.length) {
			for (let n = 0; n < this.slides.length; n++) {
				const slide = this.slides[n].cloneNode(true)
				slide.setAttribute('data-ghost', '')
				this.ghostSlides.push(slide)
				this.slideWrapper.insertBefore(slide, this.slides[0])
			}
			this.setActive()
			this.activeMilestone.addEventListener('change', () => {
				this.setActive()
			})
		}
	}

	get slideCount() {
		return this.slides.length
	}

	clearClasses(elements) {
		if (elements.length) {
			elements.forEach(element => {
				element.classList.remove('active')
				for (let n = 0; n <= this.slideCount; n++) {
					element.classList.remove(`left--${n}`)
					element.classList.remove(`right--${n}`)
				}
			})
		}
	}

	setActive() {
		const index = this.activeMilestone.cur
		this.clearClasses(this.slides)
		this.clearClasses(this.ghostSlides)
		switch (this.dir) {
			case 'prev':
				this.ghostSlides[index].classList.add('active')
				break
			case 'next':
			default:
				this.slides[index].classList.add('active')
				break
		}
		for (let n = 1; n < this.slideCount; n++) {
			if (index + n < this.slideCount) {
				this.slides[index + n].classList.add(`right--${n}`)
				this.ghostSlides[index + n].classList.add(`left--${this.slideCount - n}`)
			} else {
				this.slides[index + n - this.slideCount].classList.add(`right--${n}`)
				this.ghostSlides[index + n - this.slideCount].classList.add(`left--${this.slideCount - n}`)
			}
		}
	}
}

export default Milestones
