import debounce from 'debounce'

/**!
 * Mobile Menu
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2019
 */
export default class MobileMenu {
	html = document.querySelector('html')
	mobileMenuIsOpen = false
	openedClass = 'mobileMenuOpen'
	closedClass = 'mobileMenuClosed'
	onOpenCallback = function () {}
	onCloseCallback = function () {}
	onResponsiveCallback = function (screenWidth) {}
	mobileMenuToggles = []
	mobileMenuOpeners = []
	mobileMenuClosers = []

	constructor({
		openClass = 'mobileMenuOpen',
		closeClass = 'mobileMenuClosed',
		toggleSelector = null,
		openSelector = null,
		closeSelector = null,
		onOpen = function () {},
		onClose = function () {},
		onResponsive = function () {},
	} = {}) {
		let that = this;

		//override the defaults, if applicable
		this.openedClass = openClass
		this.closedClass = closeClass
		this.onOpenCallback = onOpen
		this.onCloseCallback = onClose
		this.onResponsiveCallback = onResponsive

		//set up the mobile menu toggles
		if (toggleSelector) {
			this.mobileMenuToggles = document.querySelectorAll(toggleSelector)
			this.mobileMenuToggles.forEach(mobileMenuToggle => {
				mobileMenuToggle.addEventListener('click', e => {
					e.preventDefault()
					this.mobileMenuIsOpen = !this.mobileMenuIsOpen
					this.toggle()
				})
			})
		}

		//set up the mobile menu openers
		if (openSelector) {
			this.mobileMenuOpeners = document.querySelectorAll(openSelector)
			this.mobileMenuOpeners.forEach(mobileMenuOpener => {
				mobileMenuOpener.addEventListener('click', e => {
					e.preventDefault()
					this.open()
				})
			})
		}

		//set up the mobile menu closers
		if (closeSelector) {
			this.mobileMenuClosers = document.querySelectorAll(closeSelector)
			this.mobileMenuClosers.forEach(mobileMenuCloser => {
				mobileMenuCloser.addEventListener('click', e => {
					e.preventDefault()
					this.close()
				})
			})
		}

		;['DOMContentLoaded', 'load', 'resize'].forEach(function(eventName){
			window.addEventListener(eventName, debounce(function(){
				that.handleResponsive()
			}, 10, false))
		})

		//initial
		this.handleResponsive()
	}

	handleResponsive() {
		if (this.onResponsiveCallback) {
			let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
			this.onResponsiveCallback(w)
		}
	}

	open() {
		this.mobileMenuIsOpen = true
		this.html.classList.add(this.openedClass)
		this.html.classList.remove(this.closedClass)
		if (this.onOpenCallback) {
			this.onOpenCallback()
		}
	}

	close() {
		this.mobileMenuIsOpen = false
		this.html.classList.add(this.closedClass)
		this.html.classList.remove(this.openedClass)
		if (this.onCloseCallback) {
			this.onCloseCallback()
		}
	}

	toggle() {
		if (this.mobileMenuIsOpen) {
			this.open()
		} else {
			this.close()
		}
	}

	isOpen() {
		return this.mobileMenuIsOpen
	}
}
