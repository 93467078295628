/**!
 * JS Class
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
let html = document.querySelector('html')
if (html) {
	html.classList.add('js')
	html.classList.remove('no-js')
}
