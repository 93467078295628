import ListenerManager from './listenerManager'
let listenerBoss = new ListenerManager()

//reference the elements
let navLinks = document.querySelectorAll('.nav ul a, .nav ul button, .mobileNav ul a, .mobileNav ul button')
let nav = document.querySelector('.nav')
let navParents = document.querySelectorAll('.nav .hasChildren, .mobileNav .hasChildren')
let toggles = document.querySelectorAll('.nav .toggle')
let toggleOpenClass = 'toggle--open'
let liOpenClass = 'isOpen'

//set up the toggles
if (toggles.length) {
	toggles.forEach(toggle => {
		let parentLi = toggle.closest('li')
		listenerBoss.register(toggle, 'click', function (e) {
			e.preventDefault()
			let isOpen = toggle.classList.contains(toggleOpenClass)
			if (isOpen) {
				//close
				toggle.classList.remove(toggleOpenClass)
				parentLi.classList.remove(liOpenClass)
				toggle.setAttribute('aria-expanded', 'false')
				toggle.setAttribute('aria-label', 'Open submenu')
			} else {
				//open
				toggle.classList.add(toggleOpenClass)
				parentLi.classList.add(liOpenClass)
				toggle.setAttribute('aria-expanded', 'true')
				toggle.setAttribute('aria-label', 'Close submenu')
			}
		})
	})
}

export default function () {
	// ---------- nav ----------
	let currentUrl = window.location.href.split('#')[0]

	//handle the selected states
	if (navLinks.length) {
		navLinks.forEach(navLink => {
			if (navLink.href === currentUrl) {
				navLink.classList.add('selected')
			} else if (currentUrl.indexOf(navLink.href) > -1) {
				navLink.classList.add('selected')
			} else {
				navLink.classList.remove('selected')
			}

			if (navLink.href === currentUrl) {
				navLink.setAttribute('aria-current', 'page')
			} else {
				navLink.removeAttribute('aria-current')
			}
		})
	}

	//make sure nav parents are set as active parents if they have an active child
	if (navParents.length) {
		navParents.forEach(navParent => {
			let hasSelectedChild = !!navParent.querySelector('.selected')
			let firstLink = navParent.querySelector(':scope > a, :scope > button')
			if (firstLink) {
				if (hasSelectedChild) {
					navParent.classList.add('activeParent')
					firstLink.classList.add('selected')
					firstLink.setAttribute('aria-expanded', 'true')
				} else {
					navParent.classList.remove('activeParent')
					firstLink.classList.remove('toggle--open')
					firstLink.setAttribute('aria-expanded', 'false')
				}
			}
		})
	}
}
