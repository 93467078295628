import { addClass, debounce, removeClass } from '../Utils/functions'

class TableTabs {
	constructor(element) {
		this.root = element
		this.content = element.querySelector('[class$=__content]')
		this.tabs = element.querySelectorAll('[data-for]')
		this.actions = element.querySelectorAll('[data-action]')
		if (this.tabs.length === 0) {
			return
		}
		this.activeTab = this.tabs[0].dataset.for
		this.targets = {}
		this.activeTarget = false
		const targetEls = element.querySelectorAll('[data-target]')
		if (targetEls.length) {
			targetEls.forEach(target => {
				if (!this.activeTarget) {
					this.activeTarget = target
				}
				const targetId = target.dataset.target
				this.targets[targetId] = target
			})
		}
		this.actions.forEach(action => {
			action.addEventListener('click', () => {
				const targetsAry = Object.values(this.targets)
				let newIndex = targetsAry.indexOf(this.activeTarget)
				switch (action.dataset.action) {
					case 'prev':
						newIndex--
						break
					case 'next':
						newIndex++
						break
				}
				newIndex = Math.max(Math.min(newIndex, targetsAry.length), 0)
				this.activeTab = targetsAry[newIndex].dataset.target
				this.activeHandler()
			})
		})
		this.tabs.forEach(tab => {
			tab.addEventListener('click', () => {
				this.activeTab = tab.dataset.for
				this.activeHandler()
			})
		})
		// Initialize the first tab
		this.activeHandler()
		window.addEventListener(
			'resize',
			debounce(75, () => {
				this.activeHandler()
			}),
		)
	}

	activeHandler() {
		if (this.activeTab !== null && this.activeTab !== undefined && this.activeTab !== false) {
			removeClass('active', [...this.tabs, ...Object.values(this.targets)])
			const target = this.targets[this.activeTab]
			this.activeTarget = target
			addClass('active', [
				...this.root.querySelectorAll(`[data-for="${this.activeTab}"]`),
				this.root.querySelector(`[data-target="${this.activeTab}"]`),
			])
			const targetHeight = target.scrollHeight
			this.content.style.height = `${targetHeight}px`
			this.actions.forEach(action => {
				const targetsAry = Object.values(this.targets)
				const targetMax = targetsAry.length - 1
				const activeIndex = targetsAry.indexOf(this.activeTarget)
				switch (action.dataset.action) {
					case 'prev':
						action.disabled = activeIndex <= 0
						break
					case 'next':
						action.disabled = activeIndex >= targetMax
						break
				}
			})
		}
	}
}

export default TableTabs
