import ListenerManager from './listenerManager'
let listenerBoss = new ListenerManager()

export default {
	init(selector = '[data-blur-on-click]') {
		this.destroy()

		let els = document.querySelectorAll(selector)
		if (els.length) {
			els.forEach(el => {
				listenerBoss.register(el, 'click', function (e) {
					e.preventDefault()
					el.blur()
				})
			})
		}
	},
	destroy() {
		listenerBoss.removeAll()
	},
}
