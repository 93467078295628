import { addClass, createElementString, remove, removeClass } from '../Utils/functions'

class PDFMerge {
	constructor(element) {
		this.root = element
		this.submit = element.querySelector('.submit')
		this.checkboxes = []
		this.refreshCheckboxes()
		this.modal = false
		this.createModal()
		this.submit.addEventListener('click', async e => {
			e.preventDefault()
			this.download()
		})
		this.changeHandler()
		this.root.addEventListener('change', () => {
			this.changeHandler()
		})
	}

	changeHandler() {
		if (this.selectedIds().length) {
			this.submit.disabled = false
		} else {
			this.submit.disabled = true
		}
	}

	createModal() {
		if (this.modal) return
		this.modal = createElementString(`<div class="small-modal">
			<div class="small-modal__contents">
				<h2></h2>
				<div class="small-modal__icon"></div>
				<p><a href="#"></a></p>
			</div>
		</div>`)
		this.modalTitle = this.modal.querySelector('h1')
		this.modalIcon = this.modal.querySelector('.small-modal__icon')
		this.modalLink = this.modal.querySelector('a')
		document.body.insertBefore(this.modal, document.body.firstElementChild)
		this.modal.addEventListener('click', e => {
			if (this.modal === e.target) {
				this.hideModal()
			}
		})
		this.modalLink.addEventListener('click', e => {
			setTimeout(() => {
				this.hideModal()
				setTimeout(() => {
					this.destroyModal()
				}, 250)
			}, 2500)
		})
	}

	destroyModal() {
		if (this.modal) {
			remove(this.modal)
			this.modal = false
		}
	}

	async showModal() {
		this.createModal()
		await Promise.resolve()
		addClass('active', this.modal)
	}

	hideModal() {
		removeClass('active', this.modal)
	}

	refreshCheckboxes() {
		this.checkboxes = this.root.querySelectorAll('[type=checkbox]')
	}

	modalSetTitle(title) {
		this.modalTitle.innerText = title
	}

	modalSetLink(url, text, download = null) {
		this.modalLink.href = url
		this.modalLink.innerText = text
		this.modalLink.download = download
	}

	selectedIds() {
		const checked = []
		if (this.checkboxes.length) {
			this.checkboxes.forEach(checkbox => {
				if (checkbox.checked) {
					checked.push(checkbox.value)
				}
			})
		}
		return checked
	}

	async download() {
		this.submit.disabled = true
		const ids = this.selectedIds()
		if (ids.length) {
			this.showModal()
			removeClass('error', this.modalIcon)
			addClass('loading', this.modalIcon)
			removeClass('checkmark', this.modalIcon)
			this.modalSetTitle('Generating PDF')
			// const start = Date.now()
			this.modalSetLink('#', '')
			try {
				const response = await fetch(`/pdf/merge?ids=${ids.join(',')}`)
				// console.log('TTFB', Date.now() - start)
				this.modalSetTitle('Downloading PDF')
				// const fileSize = response.headers.get('content-length')
				// if (fileSize) {
				// 	console.log('fileSize', fileSize)
				// }
				if (response.status === 200) {
					this.saveBlob(await response.blob())
					this.modalSetTitle('Your PDF is ready')
					removeClass('loading', this.modalIcon)
					addClass('checkmark', this.modalIcon)
					// console.log('Downloaded', Date.now() - start)
				} else {
					this.modalSetTitle('Error generating PDF')
					removeClass('loading', this.modalIcon)
					addClass('error', this.modalIcon)
				}
			} catch (err) {
				console.error(err)
				this.modalSetTitle('Error generating PDF')
				removeClass('loading', this.modalIcon)
				addClass('error', this.modalIcon)
			}
		}
		this.loading = false
		this.submit.disabled = false
	}

	async saveBlob(blob) {
		const url = window.URL.createObjectURL(blob)
		this.modalSetLink(url, 'Save', 'Sustainability Report')
		await Promise.resolve()
		this.modalLink.click()
	}
}

export default PDFMerge
