import isIE11 from '../Utils/isIE11'
import ListenerManager from './listenerManager'
import whichTransitionEvent from '../Utils/whichTransition'

let isAosLoading = false
let isPageReady = false
let hasKickedOff = false
let html = document.querySelector('html')
let listenerBoss = new ListenerManager()
let transitionEvent = whichTransitionEvent()
let aosReference = false
let isTemporarilyDisabled = false
let disabledAttribute = 'data-temp-aos'

/**
 * Completely removes aos from an element.
 *
 * @param el
 */
const removeAosFromElement = function (el) {
	el.removeAttribute('data-aos')
	el.removeAttribute('data-aos-anchor')
	el.removeAttribute('data-aos-duration')
	el.removeAttribute('data-aos-delay')
	el.removeAttribute('data-aos-easing')
	el.classList.remove('aos-animate')
	el.classList.remove('aos-init')
}

/**
 * Temporarily removes aos from an element.
 *
 * @param el
 */
const disableAosForElement = function (el) {
	let trans = el.getAttribute('data-aos')
	if (trans) {
		el.setAttribute(disabledAttribute, trans)
		el.removeAttribute('data-aos')
	}
}

/**
 * Temporarily removes aos from an element.
 *
 * @param el
 */
const restoreAosForElement = function (el) {
	let trans = el.getAttribute(disabledAttribute)
	if (trans) {
		el.setAttribute('data-aos', trans)
		el.removeAttribute(disabledAttribute)
	}
}

/**
 * Disables AOS.
 */
const disableAos = function () {
	isTemporarilyDisabled = true
	let els = document.querySelectorAll('[data-aos]')
	if (els.length) {
		els.forEach(el => {
			disableAosForElement(el)
		})
	}
}

/**
 * Restores AOS.
 */
const restoreAos = function () {
	if (!isTemporarilyDisabled) {
		return
	}
	let els = document.querySelectorAll(disabledAttribute)
	if (els.length) {
		els.forEach(el => {
			restoreAosForElement(el)
		})
	}
	isTemporarilyDisabled = false
}

/**
 * Adds the ready class to the the html element
 */
const showPage = function () {
	if (!hasKickedOff) {
		hasKickedOff = true
		if (html) {
			html.classList.add('effects--ready')
		}
	}
}

/**
 * Makes sure anchor placements are set.
 */
const setAnchorPlacements = function () {
	let els = document.querySelectorAll('[data-aos]')
	if (els.length) {
		els.forEach(el => {
			let anchorPlacement = el.getAttribute('data-aos-anchor-placement')
			if (!anchorPlacement) {
				el.setAttribute('data-aos-anchor-placement', 'top-bottom')
				el.setAttribute('data-aos-offset', '1')
			}
		})
	}
}

/**
 * Adds the class and refreshes AOS
 */
const pageIsReady = function () {
	if (isPageReady && aosReference) {
		setAnchorPlacements()
		showPage()
		requestAnimationFrame(function () {
			aosReference.refresh(true)
		})
	}
}
window.addEventListener('load', function () {
	isPageReady = true
	pageIsReady()
})
window.addEventListener('DOMContentLoaded', function () {
	isPageReady = true
	pageIsReady()
})

const run = function (enabledBreakpoint = 768) {
	if (isAosLoading) {
		return
	}

	let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

	if (width >= enabledBreakpoint) {
		if (!aosReference) {
			isAosLoading = true
			//lazyload all of the aos styles and js
			Promise.all([
				import(/* webpackChunkName: "aos", webpackPreload: true */ 'aos/dist/aos.esm'),
				import(/* webpackChunkName: "aos", webpackPreload: true */ '../../scss/base/_effects.scss'),
			]).then(([{ default: AOS }]) => {
				aosReference = AOS
				isAosLoading = false

				//fired when an element animates in
				document.addEventListener('aos:in', ({ detail: el }) => {
					if (hasKickedOff) {
						let eraseEffects = function (e) {
							if (e.target === el) {
								removeAosFromElement(el)
								listenerBoss.remove(el, transitionEvent, eraseEffects)
							}
						}

						//remove aos after the element is animated in
						listenerBoss.register(el, transitionEvent, eraseEffects)
					}
				})
				aosReference.init({
					anchorPlacement: 'top-bottom',
					disable: function () {
						return width < enabledBreakpoint
					},
					easing: 'ease-out-sine',
					offset: 0,
					once: false,
					startEvent: isIE11 ? 'DOMContentLoaded' : 'load',
				})
				pageIsReady()
			})
		} else {
			pageIsReady()
		}
	} else {
		isPageReady = true
		showPage()
	}
}

export default {
	disableAos,
	restoreAos,
	run,
	showPage,
}
